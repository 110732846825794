import React, { useEffect } from 'react';
import './ComingSoon.css';
import ethlogo from './assets/ethereumLogo.svg';
import ReactGA from 'react-ga4';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faTelegramPlane } from '@fortawesome/free-brands-svg-icons';

const ComingSoon: React.FC = () => {
 useEffect(() => {
  ReactGA.send({
   hitType: 'pageview',
   page: '/',
   title: 'soon',
  });

  const audio = document.getElementById('audio') as HTMLAudioElement;

  function showContent() {
   const entryButton = document.getElementById('entryButton') as HTMLElement;
   const content = document.getElementById('content') as HTMLElement;

   entryButton.style.opacity = '0';
   entryButton.style.pointerEvents = 'none';

   setTimeout(() => {
    entryButton.style.display = 'none';
    content.style.display = 'block';

    audio.play();

    document.body.style.overflow = 'auto';
   }, 500);
  }

  document
   .getElementById('entryButton')
   ?.addEventListener('click', showContent);

  document.body.classList.add('blur');

  return () => {
   document
    .getElementById('entryButton')
    ?.removeEventListener('click', showContent);
  };
 }, []);

 return (
  <div className="container">
   <div id="entryButton" className="enter">
    Click To Enter
   </div>
   <div id="content">
    <div id="comingSoon">
     <h1>COMING SOON</h1>
     <h4>Dev's Cooking 🧑‍🍳</h4>
     <div className="logos"></div>
     <div className="icons">
      <a
       href="https://x.com/dexmapapp"
       target="_blank"
       rel="noopener noreferrer"
      >
       <FontAwesomeIcon icon={faXTwitter} size="2x" />
      </a>
      <a
       href="https://t.me/DexMapPortal"
       target="_blank"
       rel="noopener noreferrer"
      >
       <FontAwesomeIcon icon={faTelegramPlane} size="2x" />
      </a>
     </div>
    </div>
    <audio id="audio" controls autoPlay loop style={{ display: 'none' }}>
     <source src="skidee.xyz.mp3" type="audio/mp3" />
     Your browser does not support the audio tag.
    </audio>
   </div>
  </div>
 );
};

export default ComingSoon;
